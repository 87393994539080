<template>
  <div class="login-right-section">
    <div>
      <p>
        {{ $t('login.not_registered_yet') }}
        <router-link id="register_now_login" to="/register" class="break-words">
          {{ $t('login.register_now') }}
        </router-link>
      </p>
      <picture>
        <img
          src="../assets/images/safe-total-boxes-newest_large.png"
          alt="Internet Security and F-Secure boxes"
        />
      </picture>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.login-right-section {
  text-align: center;

  p {
    margin: 0;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
    color: #333333;

    a {
      color: $primary-color;
      font-weight: bold;
    }

    &:first-child {
      padding: 40px 40px 0px 40px;
    }

    &:last-child {
      padding-bottom: 48px;
    }
  }
  picture {
    display: inline-block;
    max-width: 391px;
    line-height: 0;
    height: 377px;
    overflow: hidden;
    padding-top: 60px;
  }
}

.break-words {
  word-break: break-all;
  display: inline-block;
  white-space: normal;
}
</style>
