<template>
  <iframe
    class="dynamic-iframe"
    :class="{ 'is-loading': isLoading }"
    :src="dSrc"
    frameborder="0"
    @load="onLoad"
  />
</template>
<script>
export default {
  props: {
    dSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true
    }
  },
  updated() {
    // this.iframeSource = this.$props.dSrc
  },
  mounted() {
    window.addEventListener('load', this.onWindowLoad)
    this.$root.$on('state_pop_window_loaded', this.onStatePopWindowLoaded)
  },
  beforeDestroy() {
    window.removeEventListener('load', this.onWindowLoad)
  },
  destroyed() {
    this.$root.$off('state_pop_window_loaded', this.onStatePopWindowLoaded)
  },
  methods: {
    onLoad() {
      if (this.iframeSource === '') {
        return
      }

      this.isLoading = false
    },
    onWindowLoad() {
      // this.iframeSource = this.$props.dSrc
    },
    onStatePopWindowLoaded() {
      this.onWindowLoad()
    }
  }
}
</script>
<style lang="scss" scoped>
.dynamic-iframe {
  &.is-loading {
    background-image: url(../assets/images/spinner.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
