var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    staticClass: "dynamic-iframe",
    class: { "is-loading": _vm.isLoading },
    attrs: { src: _vm.dSrc, frameborder: "0" },
    on: { load: _vm.onLoad },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }