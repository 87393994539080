<template>
  <div class="two-col-box" :class="{ wide: isWide, 'even-wider': isEvenWider }">
    <div>
      <div>
        <slot name="col1" />
      </div>
      <div>
        <slot name="col2" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isWide: {
      type: Boolean,
      default: false
    },
    isEvenWider: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.two-col-box {
  > div {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    max-width: 960px;
    min-width: 320px;
    margin: 0 auto;
    min-height: 300px;
  }

  &.wide {
    > div {
      max-width: 980px;
    }
  }

  &.even-wider {
    > div {
      max-width: 1018px;
    }
  }
}

@media (min-width: $desktop-min-width) {
  .two-col-box {
    > div {
      margin: -70px auto 100px auto;
      display: flex;
      align-content: stretch;

      > div {
        width: 50%;
      }
    }
  }
}
</style>
