<template>
  <base-view>
    <template #top>
      <the-header no-shadow is-wide />
      <the-title-section :title="title" is-padded is-mobile-centered />
      <two-col-box is-wide>
        <template #col1>
          <the-login-left-section />
        </template>
        <template #col2>
          <the-login-right-section />
        </template>
      </two-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer is-visible-on-mobile />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import TwoColBox from '@/components/TwoColBox.vue'
import TheLoginLeftSection from '@/components/TheLoginLeftSection.vue'
import TheLoginRightSection from '@/components/TheLoginRightSection.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'

export default {
  components: {
    TheHeader,
    TheTitleSection,
    TwoColBox,
    TheLoginLeftSection,
    TheLoginRightSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView
  },
  computed: {
    title() {
      return this.$t('login.hl_welcome')
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('login-view-entered', to.path, from.path)
    })
  }
}
</script>
