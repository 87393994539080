var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-right-section" }, [
    _c("div", [
      _c(
        "p",
        [
          _vm._v(" " + _vm._s(_vm.$t("login.not_registered_yet")) + " "),
          _c(
            "router-link",
            {
              staticClass: "break-words",
              attrs: { id: "register_now_login", to: "/register" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("login.register_now")) + " ")]
          ),
        ],
        1
      ),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/safe-total-boxes-newest_large.png"),
          alt: "Internet Security and F-Secure boxes",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }