var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "two-col-box",
      class: { wide: _vm.isWide, "even-wider": _vm.isEvenWider },
    },
    [
      _c("div", [
        _c("div", [_vm._t("col1")], 2),
        _c("div", [_vm._t("col2")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }